/**
** JAVASCRIPTS
** Name: Property
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-property').length > 0) {


    /**
    ** VARIABLES
    ****************************************/

    var $win     = $(window),
        $this    = $('.js-property'),
        $slider  = $this.find('.js-property-items'),
        $prev    = $this.find('.js-property-prev'),
        $next    = $this.find('.js-property-next'),
        $current = $this.find('.js-property-current'),
        $total   = $this.find('.js-property-total');


    /**
    ** EVENTS
    ****************************************/

    $slider.on('init', function(event, slick) {
      $total.html(slick.slideCount);
    });

    $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      $current.html(nextSlide+1);
    });


    /**
    ** INIT
    ****************************************/

    $slider.slick({
      autoplay: false,
      speed: 1000,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false,
      mobileFirst: true
    });

    // PREV
    $prev.on('click', function() {
      $slider.slick('slickPrev');
    });

    // NEXT
    $next.on('click', function() {
      $slider.slick('slickNext');
    });

  }

})();
