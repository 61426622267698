// import { throttle, debounce } from 'throttle-debounce';

import AOS from 'aos';

import './front/components/header';
import './front/components/navigation';
import './front/components/search';
import './front/components/budget';
import './front/components/bookmark';
import './front/components/property';
import './front/components/welcome';
import './front/components/reviews';
import './front/components/sorter';
import './front/components/timeline';
import './front/components/phone';
import './front/components/popin';
import './front/components/accordion';
import "./front/components/Glightbox/lifecycle";

AOS.init();
