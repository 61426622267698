/**
** JAVASCRIPTS
** Name: Popin
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelectorAll('.js-popin-ajax').length > 0) {


    /**
    ** Variables
    ****************************************/

    const $buttons = document.querySelectorAll('.js-popin-ajax');


    /**
    ** Functions
    ****************************************/

    async function fetchPopin(request, $container) {
      try {
        fetch(request).then(function(response) {
          return response.text();
        }).then(function(string) {
          const parser = new DOMParser();
          const $html  = parser.parseFromString(string, 'text/html');
          const $popin = $html.querySelector('.js-popin');

          $container.appendChild($popin);

          const $close = $popin.querySelector('.js-popin-close');

          if($close) {
            $close.addEventListener('click', (event)=>{
              event.preventDefault();
              $popin.remove();
            });
          }
        });
      } catch (error) { console.error("Error:", error); }
    }


    /**
    ** Events
    ****************************************/

    $buttons.forEach(function($button) {
      const url = $button.dataset.href;
      const $container = document.querySelector('.js-popin-container');

      if(url && $container) {
        $button.addEventListener('click', (event)=>{
          event.preventDefault();
          const myRequest = new Request(url, { method: 'GET' });
          fetchPopin(myRequest, $container);
        });
      }
    });


  }

})();
