/**
** JAVASCRIPTS
** Name: Welcome
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-welcome').length > 0) {


    /**
    ** VARIABLES
    ****************************************/

    var $win    = $(window),
        $this   = $('.js-welcome'),
        $slider = $this.find('.js-welcome-items'),
        $prev   = $this.find('.js-welcome-prev'),
        $next   = $this.find('.js-welcome-next'),
        active  = false;


    /**
    ** FUNCTIONS
    ****************************************/

    function mount() {
      $slider.slick({
        autoplay: false,
        speed: 1000,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 719,
            settings: {
              slidesToShow: 2,
            }
          }
        ]
      });
      active = true;
    }

    function dismount() {
      $slider.slick('unslick');
      active = false;
    }


    /**
    ** INIT
    ****************************************/

    if($win.outerWidth() < 960) {
      mount();
    }


    /**
    ** UPDATE
    ****************************************/

    $win.on('resize', function(event) {

      if($win.outerWidth() < 960 && !active) {
        mount();
      }

      if($win.outerWidth() >= 960 && active) {
        dismount();
      }

    });

    // PREV
    $prev.on('click', function() {
      $slider.slick('slickPrev');
    });

    // NEXT
    $next.on('click', function() {
      $slider.slick('slickNext');
    });

  }

})();
