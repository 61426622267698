/**
** JAVASCRIPTS
** Name: Sorter
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-sorter')) {


    /**
    ** Variables
    ****************************************/

    const $sorter = document.querySelector('.js-sorter');
    const $button = $sorter.querySelector('.js-sorter-button');


    /**
    ** Event
    ****************************************/

    $button.addEventListener('click', (event)=>{
      event.preventDefault();
      $sorter.classList.toggle('is-active');
    });


  }

})();
