/**
** JAVASCRIPTS
** Name: Header
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-header')) {


    /**
    ** Variables
    ****************************************/

    const $html     = document.querySelector('html');
    const $header   = document.querySelector('.js-header');
    const $menu     = document.querySelector('.js-header-menu');
    const $items    = document.querySelectorAll('.js-header-item');
    const $headings = document.querySelectorAll('.js-header-heading');
    const $backs    = document.querySelectorAll('.js-header-back');

    const $prehead  = document.querySelector('.js-preheader');


    /**
    ** Pre-header
    ****************************************/

    if($prehead) {
      const $navs = document.querySelector('.js-header-navigations');
      const $subnavs = document.querySelectorAll('.js-header-subnav');

      window.addEventListener("load", (event)=>{
        if(window.innerWidth < 1200) {
          $navs.style.marginTop = $prehead.clientHeight + "px";

          if($subnavs.length > 0) {
            $subnavs.forEach(function($subnav) {
              $subnav.style.marginTop = $prehead.clientHeight + "px";
            });
          }
        }
      });

      window.addEventListener("resize", (event)=>{
        if(window.innerWidth < 1200) {
          $navs.style.marginTop = $prehead.clientHeight + "px";

          if($subnavs.length > 0) {
            $subnavs.forEach(function($subnav) {
              $subnav.style.marginTop = $prehead.clientHeight + "px";
            });
          }
        }
        else {
          $navs.style.removeProperty("margin-top");

          if($subnavs.length > 0) {
            $subnavs.forEach(function($subnav) {
              $subnav.style.removeProperty("margin-top");
            });
          }
        }
      });
    }


    /**
    ** Menu event
    ****************************************/

    $menu.addEventListener('click', (event)=>{
      event.preventDefault();
      $html.classList.toggle('menu-active');
    });


    /**
    ** Headings event
    ****************************************/

    document.addEventListener('click', (event)=>{
      var $heading = false;

      if(event.target.classList.contains('js-header-heading')) {
        $heading = event.target;
      }
      else if(event.target.closest('.js-header-heading')) {
        $heading = event.target.closest('.js-header-heading');
      }

      // Click on heading
      if($heading) {
        var $item = $heading.closest('.js-header-item');

        if($item.classList.contains('is-active')) {
          $item.classList.remove('is-active');
        }
        else {
          if($header.querySelector('.js-header-item.is-active')) {
            $header.querySelector('.js-header-item.is-active').classList.remove('is-active');
          }
          $item.classList.add('is-active');
        }
      }

      // Click somewhere else
      else {
        if(window.innerWidth >= 1200 && $header.querySelector('.js-header-item.is-active')) {
          $header.querySelector('.js-header-item.is-active').classList.remove('is-active');
        }
      }

    });


    /**
    ** Backs event
    ****************************************/

    $backs.forEach(function($back) {
      const $item = $back.closest('.js-header-item');

      $back.addEventListener('click', (event)=>{
        event.preventDefault();
        $item.classList.remove('is-active');
      });
    });


  }

})();
