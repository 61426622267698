/**
** JAVASCRIPTS
** Name: Search
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-search')) {


    /**
    ** Variables
    ****************************************/

    const $search = document.querySelector('.js-search');
    const $forms  = $search.querySelectorAll('.js-search-form');
    const $tabs   = $search.querySelectorAll('.js-search-tab');


    /**
    ** Event
    ****************************************/

    $tabs.forEach(function($tab) {
      const type  = $tab.dataset.type;
      const $form = $search.querySelector('.js-search-form[data-type="' + type + '"]');

      $tab.addEventListener('click', (event)=>{
        event.preventDefault();

        $forms.forEach(function($currentForm) {
          if($currentForm != $form) {
            $currentForm.classList.remove('is-active');
          }
        });

        $tabs.forEach(function($currentTab) {
          if($currentTab != $tab) {
            $currentTab.classList.remove('is-active');
          }
        });

        $form.classList.add('is-active');
        $tab.classList.add('is-active');
      });
    });


  }

})();
