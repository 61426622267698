import Glightbox from "./Glightbox";

document.addEventListener("DOMContentLoaded", () => {
  const glightboxElement = document.querySelectorAll(".glightbox");
  if (!glightboxElement.length) {
    return;
  }

  glightboxElement.forEach((element) => {
    const glightboxE = new Glightbox(element);
    glightboxE.mount(element);
  });

  const elementToWatch = document.documentElement;

  const observer = new MutationObserver((mutationsList) => {
    for (const mutation of mutationsList) {
      if (
        mutation.type === "attributes" &&
        mutation.attributeName === "class"
      ) {
        const newClass = elementToWatch.className;
        if (newClass.includes("glightbox-open")) {
          // Button Gclose
          let gclose = document.querySelector(".gclose");
          gclose.insertAdjacentHTML(
            "afterbegin",
            '<span class="glightbox__label">Fermer</span>'
          );

          // Name
          const glightboxElements = document.querySelectorAll(".glightbox");
          const gslide = document.querySelectorAll(".gslide");

          const dataNames = [];

          for (let i = 0; i < glightboxElements.length; i++) {
            const element = glightboxElements[i];
            const dataName = element.getAttribute("data-name");
            dataNames.push(dataName);
          }

          for (let i = 0; i < gslide.length; i++) {
            const element = gslide[i];
            const lightboxNameDiv = document.createElement('div');
            lightboxNameDiv.classList.add('glightbox__name');
            lightboxNameDiv.textContent = dataNames[i];
            gslide[i].appendChild(lightboxNameDiv);
          }
        }
      }
    }
  });

  observer.observe(elementToWatch, { attributes: true });
});
