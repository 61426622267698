document.addEventListener("DOMContentLoaded", () => {
  const accordions = document.querySelectorAll('.js-accordion');
  accordions.forEach((accordion) => {
      const button = accordion.querySelector('.js-accordion-btn');
      const content = accordion.querySelector('.js-accordion-content');

      button.addEventListener('click', () => {
          console.log('youtpi')
          content.classList.toggle('none');
      });
  });

});
