/**
** JAVASCRIPTS
** Name: Phone
********************************************************************************/

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  const $phones = document.querySelectorAll('.js-phone');


  /**
  ** Event
  ****************************************/

  $phones.forEach(function($phone) {

    $phone.addEventListener('click', (event)=>{
      event.preventDefault();

      const number  = $phone.dataset.number;

      $phone.parentNode.innerHTML = '<p><a href="tel:' + number + '">' + number + '</a></p>';
    });
  });



})();
