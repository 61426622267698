/**
** JAVASCRIPTS
** Name: Budget
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-budget')) {
    console.log('budget');


    /**
    ** Variables
    ****************************************/

    const $budget = document.querySelector('.js-budget');
    const $button = $budget.querySelector('.js-budget-button');
    const $popin  = $budget.querySelector('.js-budget-popin');


    /**
    ** Events
    ****************************************/

    document.addEventListener('click', (event)=>{
      if(event.target.classList.contains('js-budget-button') || event.target.closest('.js-budget-button')) {
        event.preventDefault();
        $budget.classList.toggle('is-opened');
        if($budget.classList.contains('is-opened')) {
          $budget.querySelectorAll('input')[0].focus();
        }
      }
      else if(!event.target.classList.contains('js-budget-popin') && !event.target.closest('.js-budget-popin')) {
        $budget.classList.remove('is-opened');
      }
    });


  }

})();
