/**
** JAVASCRIPTS
** Name: Reviews
********************************************************************************/


/**
** Pre-imports
****************************************/

import $ from 'jquery';
import 'slick-carousel';


/**
** SLIDERS
****************************************/

(function() {
  'use strict';

  if($('.js-reviews').length > 0) {

    $('.js-reviews').each(function() {
      var $this   = $(this),
          $slider = $this.find('.js-reviews-items'),
          $prev   = $this.find('.js-reviews-prev'),
          $next   = $this.find('.js-reviews-next');

      $slider.slick({
        autoplay: false,
        speed: 1000,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: false,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 719,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      });

      // PREV
      $prev.on('click', function() {
        $slider.slick('slickPrev');
      });

      // NEXT
      $next.on('click', function() {
        $slider.slick('slickNext');
      });


    });

  }

})();
