/**
** JAVASCRIPTS
** Name: Bookmark
********************************************************************************/

(function() {
  'use strict';


  /**
  ** Variables
  ****************************************/

  const $bookmarks = document.querySelectorAll('.js-bookmark');


  /**
  ** Functions
  ****************************************/

  async function fetchBookmark(request, updatedState, $bookmark, text, icon) {
    try {
      const response = await fetch(request);
      if (!response.ok) { throw new Error("Network response was not OK"); }

      // Set data active
      $bookmark.dataset.active = updatedState;

      // Set text
      if($bookmark.querySelector('.js-bookmark-label')) {
        $bookmark.querySelector('.js-bookmark-label').innerHTML = text;
      }

      // Set Icon
      if($bookmark.querySelector('.js-bookmark-icon')) {
        var $use = $bookmark.querySelector('.js-bookmark-icon use');
        var currentHref = $use.getAttribute('xlink:href');
        var newHref = currentHref.substring(0, currentHref.indexOf('#')) + "#sprite-" + icon;
        $use.setAttribute('href', newHref);
      }

      // Animate
      if(updatedState == "true") {
        $bookmark.classList.add('animate');
      }
      else {
        $bookmark.classList.remove('animate');
      }

    } catch (error) { console.error("Error:", error); }
  }


  /**
  ** Event
  ****************************************/

  $bookmarks.forEach(function($bookmark) {

    $bookmark.addEventListener('click', (event)=>{
      event.preventDefault();

      var active = ($bookmark.dataset.active == "true") ? true : false;


      /**
      ** Remove from bookmarks
      ****************************************/

      if(active) {
        const myRequest = new Request($bookmark.dataset.remove, { method: 'POST' });
        fetchBookmark(myRequest, "false", $bookmark, $bookmark.dataset.addText, $bookmark.dataset.addIcon);
      }


      /**
      ** Add to bookmarks
      ****************************************/

      else {
        const myRequest = new Request($bookmark.dataset.add, { method: 'POST' });
        fetchBookmark(myRequest, "true", $bookmark, $bookmark.dataset.removeText, $bookmark.dataset.removeIcon);
      }


    });
  });



})();
