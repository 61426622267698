/**
** JAVASCRIPTS
** Name: Timeline
********************************************************************************/

(function() {
  'use strict';


  if(document.querySelector('.js-timeline')) {


    /**
    ** Variables
    ****************************************/

    const $html  = document.querySelector('html');
    const $items = document.querySelectorAll('.js-timeline-item');

    var items = [];

    let lastKnownScrollPosition = 0;
    let lastKnownScrollCenter = Math.round(lastKnownScrollPosition + window.outerHeight / 2);
    let ticking = false;


    /**
    ** Functions
    ****************************************/

    function initItems() {
      $items.forEach(function($item) {
        var offset = Math.round($item.getBoundingClientRect().top + document.documentElement.scrollTop);
        items.push({
          offset: offset,
          element: $item
        });
      });
    };


    /**
    ** Resize
    ****************************************/

    window.addEventListener('resize', (event) => {
      items = [];
      initItems();
    });


    /**
    ** Init
    ****************************************/

    initItems();


    /**
    ** Scroll
    ****************************************/

    document.addEventListener('scroll', (event) => {
      lastKnownScrollPosition = Math.round(window.scrollY);
      lastKnownScrollCenter = Math.round(lastKnownScrollPosition + window.outerHeight / 2);

      if (!ticking) {
        window.requestAnimationFrame(() => {
          items.forEach(function(item) {
            if(lastKnownScrollCenter >= item.offset) {
              item.element.classList.add('is-active');
            }
            else {
              item.element.classList.remove('is-active');
            }
          });
          ticking = false;
        });

        ticking = true;
      }
    });


  }

})();
